import { useEffect, useState } from "react"

export function useOpenDetail() {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    function handleOpen() {
      setOpen(window.innerWidth > 768)
    }

    window.addEventListener("resize", handleOpen)

    handleOpen()

    return () => window.removeEventListener("resize", handleOpen)
  }, [])
  return open
}
