import React from "react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useOpenDetail } from "../../util/hooks"
import "./table-of-contents.scss"

const getIds = items =>
  items.reduce((acc, item) => {
    if (item.url) {
      acc.push(item.url.slice(1))
    }
    if (item.items) {
      acc.push(...getIds(item.items))
    }
    return acc
  }, [])

const useActiveId = itemIds => {
  const [activeId, setActiveId] = useState(``)
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -80% 0%` }
    )
    itemIds.forEach(id => {
      observer.observe(document.getElementById(id))
    })
    return () => {
      itemIds.forEach(id => {
        observer.unobserve(document.getElementById(id))
      })
    }
  }, [itemIds])
  return activeId
}

const renderItems = (items, activeId) => (
  <ol className="toc">
    {items.map((item, idx) => {
      const classes =
        activeId === item.url.slice(1)
          ? "toc-item toc-item--active"
          : "toc-item"
      return (
        <li key={item.url}>
          <a className={classes} href={item.url}>
            {item.title}
          </a>
          {item.items && renderItems(item.items, activeId)}
        </li>
      )
    })}
  </ol>
)

const TableOfContents = ({ items }) => {
  const { t } = useTranslation("projects")
  const idList = getIds(items)
  const activeId = useActiveId(idList)
  const open = useOpenDetail()

  return (
    <details open={open}>
      <summary>{t("TABLE_OF_CONTENTS")}</summary>
      {renderItems(items, activeId)}
    </details>
  )
}
export default TableOfContents
