import React, { Fragment } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import "./hardware.scss"
import { useToggle } from "../../hooks/toggle"
import ExternalLink from "../../components/external-link"
import { TiShoppingCart } from "react-icons/ti"
import Button from "../../components/button"
import { useOpenDetail } from "../../util/hooks"

const Hardware = ({ items }) => {
  const { t } = useTranslation("projects")
  const [isToggled, toggle] = useToggle(false)
  const open = useOpenDetail()

  return (
    <details open={open}>
      <summary>{t(!isToggled ? "HARDWARE" : "SHOPPING_LIST")}</summary>
      {isToggled && (
        <p>
          <a
            className="explanation-link"
            href="/privacy-policy#affiliate-links"
          >
            {t("AFFILIATE_LINKS")}
          </a>
        </p>
      )}
      <table className="hardware">
        <tbody>
          {items.map((item, idx) => (
            <Fragment key={idx}>
              <tr className="hardware_row">
                <td className="left">{item.amount}</td>
                <td colSpan="2">{item.name}</td>
              </tr>
              {isToggled && (
                <tr className="shopping_row">
                  <td className="icon">
                    <TiShoppingCart />
                  </td>
                  {item.shopping &&
                    item.shopping.map(shop => (
                      <td key={shop.link}>
                        <ExternalLink href={shop.link}>
                          {shop.name}
                        </ExternalLink>
                      </td>
                    ))}
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>

      <footer className="shopping-button">
        <Button onClick={toggle}>
          {t(!isToggled ? "SHOPPING_LIST" : "HARDWARE")}
        </Button>
      </footer>
    </details>
  )
}
export default Hardware
