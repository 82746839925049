/* eslint-disable react/jsx-pascal-case */
import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { MDXRenderer } from "gatsby-plugin-mdx"
import "./project-post.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TableOfContents from "./table-of-contents"
import MonsterRating from "../../components/monster-rating"
import Hardware from "./hardware"
import GitHubLogo from "./github-logo.png"
import ExternalLink from "../../components/external-link"
import BackTopButton from "../../components/back-top-button"
import { useOpenDetail } from "../../util/hooks"

const ProjectPostTemplate = ({ data }) => {
  const { t } = useTranslation("projects")
  const post = data.mdx
  const { next, previous } = data
  const open = useOpenDetail()

  return (
    <Layout itemType="http://schema.org/TechArticle">
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <nav className="blog-post-nav">
        {previous && (
          <a href={previous.fields.slug}>
            <span>←</span> {previous.frontmatter.title}
          </a>
        )}
        {next && (
          <a href={next.fields.slug} className="right">
            {next.frontmatter.title} <span>→</span>
          </a>
        )}
      </nav>
      <aside className="project-sidebar">
        <details open={open}>
          <summary>{t("LEVEL")}</summary>
          <MonsterRating level={post.frontmatter.level}></MonsterRating>
        </details>
        {post.tableOfContents?.items && (
          <TableOfContents items={post.tableOfContents.items} />
        )}
        {post.frontmatter.hardware && (
          <Hardware items={post.frontmatter.hardware} />
        )}
        {post.frontmatter.github && (
          <ExternalLink href={post.frontmatter.github} className="github">
            <img src={GitHubLogo} alt="github logo" /> <span>source code</span>
          </ExternalLink>
        )}
      </aside>
      <article itemProp="articleBody" className="project-main">
        <MDXRenderer>{post.body}</MDXRenderer>
      </article>
      <BackTopButton></BackTopButton>
    </Layout>
  )
}

export default ProjectPostTemplate

export const projectPageQuery = graphql`
  query ProjectPostBySlug(
    $id: String!
    $language: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      tableOfContents
      frontmatter {
        title
        date
        description
        slug
        level
        github
        hardware {
          name
          amount
          shopping {
            name
            link
          }
        }
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
