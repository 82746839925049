import React from 'react'
import "./button.scss"

const Button = ({children, ...props}) => (
  <button {...props} className="base-button">
    <span className="circle" aria-hidden="true">
      <span className="icon arrow"></span>
    </span>
    <span className="button-text">{children}</span>
  </button>
)

export default Button